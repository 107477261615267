<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card class="mx-auto" max-width="400" tile>
      <v-card-title class="text-h4 primary lighten-2"> {{ $t('organizations.chooseOrganization') }} </v-card-title>
      <v-list flat>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in userOrganizations" :key="i" @click="emitOrg(item)">
            <v-list-item-icon>
              <v-icon>mdi-office-building</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> {{ item.name }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'OrgSelector',
  props: {
    value: {type: Boolean, default: false},
    items: {type: Array, default: () => []}
  },
  computed: {
    ...mapGetters(['userOrganizations']),
    dialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    emitOrg(item) {
      this.$emit('select', item)
    }
  }
}
</script>
