<template>
  <v-app>
    <v-row dense no-gutters>
      <v-col cols="12" md="5" class="d-none d-md-block">
        <v-img src="@/assets/img/base/bg-login.png" height="100vh" position="bottom center" />
      </v-col>

      <v-col cols="12" md="7" class="d-flex">
        <ValidationObserver v-slot="{handleSubmit}" class="row no-gutters">
          <v-container
            class="col-9 col-sm-7 col-md-7 col-lg-6 d-flex flex-column justify-center py-10"
            tag="form"
            @keydown.enter="handleSubmit(login)"
          >
            <div class="mt-auto mb-8">
              <v-tabs grow color="primary" v-model="tabIndex">
                <v-tab> {{ $t('account.admin') }} </v-tab>
                <v-tab> {{ $t('organizations.organization') }} </v-tab>
              </v-tabs>
            </div>

            <div>{{ $t('account.welcomeBack') }}</div>
            <h1 class="mb-7">{{ $t('account.loginTOYourAccount') }}</h1>

            <div class="mb-8">
              <v-tabs color="primary" v-model="usernameType" @change="changeType">
                <v-tab> {{ $t('shared.mobile') }} </v-tab>
                <v-tab> {{ $t('shared.email') }} </v-tab>
              </v-tabs>
            </div>

            <v-tabs-items v-model="usernameType">
              <v-tab-item>
                <mobile-input
                  v-model="model.mobile"
                  :code.sync="model.countryCode"
                  dir="ltr"
                  key="mobile"
                  :label="$t('shared.mobile')"
                  class="mb-2"
                  placeholder="1234567890"
                  :rules="mobileRules"
                  @keydown.enter="handleSubmit(getRecoveryCode)"
                />
              </v-tab-item>

              <v-tab-item>
                <c-input
                  v-model="model.email"
                  dir="ltr"
                  key="email"
                  :label="$t('shared.email')"
                  class="mb-2"
                  placeholder="example@gmail.com"
                  :rules="emailRules"
                  @keydown.enter="handleSubmit(getRecoveryCode)"
                />
              </v-tab-item>
            </v-tabs-items>

            <c-input
              v-model="model.password"
              :label="$t('shared.password')"
              :placeholder="$t('account.enterYourPassword')"
              rules="required"
              type="password"
            />

            <router-link class="mb-5" to="/forgot-password"> {{ $t('account.forgotPassword') }} </router-link>

            <v-btn class="mb-3" color="success" @click.prevent="handleSubmit(login)" :loading="progressing">
              {{ $t('account.loginNow') }}
            </v-btn>

            <v-btn class="mb-auto" color="info" text to="/login-otp"> {{ $t('account.loginViaOTP') }} </v-btn>

            <div>
              {{ $t('account.dontHaveAnAccount') }}
              <router-link to="/register"> {{ $t('account.JoinFreeToday') }} </router-link>
            </div>
          </v-container>
        </ValidationObserver>
      </v-col>
    </v-row>
    <OrgSelector v-model="openDialog" @select="setCurrentOrg" />
  </v-app>
</template>

<script>
import {mapMutations} from 'vuex'
import OrgSelector from './components/OrgSelector'
export default {
  name: 'Login',
  components: {
    OrgSelector
  },
  data() {
    return {
      tabIndex: 0,
      usernameType: 0,
      openDialog: false,
      model: {
        username: null,
        email: null,
        mobile: null,
        countryCode: 'AU',
        password: null
      }
    }
  },
  computed: {
    mobileRules() {
      const rules = {}
      if (!this.usernameType) {
        rules.required = true
      }
      return rules
    },
    emailRules() {
      const rules = {email: true}
      if (this.usernameType) {
        rules.required = true
      }
      return rules
    },
    scope() {
      const mapper = {
        0: 'ADMIN',
        1: 'ORG'
      }
      return mapper[this.tabIndex]
    }
  },

  methods: {
    ...mapMutations({
      setAccount: 'SET_ACCOUNT',
      setOrgId: 'SET_ORG_ID'
    }),
    changeType() {
      if (this.usernameType) {
        this.model.mobile = null
      } else {
        this.model.email = null
      }
    },
    async login() {
      const model = {
        username: null,
        countryCode: this.model.countryCode,
        password: this.model.password
      }
      if (this.usernameType) {
        model.username = this.model.email
      } else {
        model.username = this.model.mobile
      }

      try {
        this.progressing = true
        const res = await this.$api.accountPub.login(model, this.scope)
        this.setAccount(res)
        if (!this.hasAnyPermission(res)) {
          this.$showError(this.$t('errors.ACCESS_DENIED'))
          return
        }
        if (this.scope === 'ORG') {
          if (res.organizations && res.organizations.length === 1) {
            this.setCurrentOrg(res.organizations[0])
          } else {
            this.openDialog = true
          }
        } else {
          this.gotoTargetPage()
        }
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    },
    hasAnyPermission(userInfo) {
      let hasPermission = true

      if (this.scope === 'ORG') {
        if (this.$isEmpty(userInfo.organizations)) {
          hasPermission = false
        } else {
          const hasAnyOrgPermission = userInfo.organizations.some((org) => !this.$isEmpty(org.permissions))
          if (!hasAnyOrgPermission) {
            hasPermission = false
          }
        }
      } else if (this.$isEmpty(userInfo.permissions)) {
        hasPermission = false
      }

      return hasPermission
    },
    gotoTargetPage() {
      const homePageUrl = this.$config.homeUrl
      const returnUrl = this.$route.query.go
      const path = returnUrl || homePageUrl
      this.$router.push(path)
    },
    setCurrentOrg(org) {
      this.setOrgId(org.id)
      this.gotoTargetPage()
    }
  }
}
</script>
